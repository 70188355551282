import { IonInput, IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { useState } from "react";
import myUtil from "../helpers/cha-utils";
import ChaUnit from "../interfaces/Unit";
import classes from "./SelectTextBox.module.css";
import {
  IonInputCustomEvent,
  IonSelectCustomEvent,
  SelectChangeEventDetail,
} from "@ionic/core/components";

const compareWith = (o1: ChaUnit, o2: ChaUnit) => {
  if (!o1 || !o2) {
    return o1 === o2;
  }

  if (Array.isArray(o2)) {
    return o2.some((o) => o.id === o1.id);
  }

  return o1.id === o2.id;
};

type Props = {
  controlId: number;
  label: string;
  subLabel?: string;
  input: string;
  value: number;
  textSize?: 1 | 2 | 3 | 4 | 5 | 6;
  onIonInput: (
    controlId: number,
    user_input: string,
    user_value: number
  ) => void;
  items: ChaUnit[];
};
const SelectTextBox: React.FC<Props> = (props) => {
  let inputClasses = classes.input;
  if (props.textSize) inputClasses += " " + classes["ts" + props.textSize];

  const [currentUnit, setCurrentUnit] = useState(
    props.items.find((item) => item.multiplier === 1)
  );

  const displayValue = myUtil.pfn(+props.value * currentUnit!.multiplier);
  // const displayInput =
  //   currentUnit!.multiplier === 1 ? props.input : displayValue;
  const displayInput = props.input;

  console.log(displayInput);
  console.log(displayValue);
  console.log(props.value);
  // const formatDisplayValue = myUtil.formatNumber(
  //   parseFloat(props.value) * currentUnit!.multiplier
  // );

  // console.log(currentUnit);

  // const inputChangeHandler = (event: IonInputCustomEvent<InputEvent>) => {
  //   // const inputHandler = (target: HTMLIonInputElement) => {\
  //   // event.preventDefault();
  //   // return;
  //   const target = event.target;
  //   // const regEx = /^-?[0-9]*[.][0-9]+$/;
  //   // console.log(regEx.test(target.value!.toString()));
  //   // if (!regEx.test(target.value!.toString())) {
  //   //   props.onIonInput(props.controlId, props.value);
  //   //   return;
  //   // } else {

  //   if (target.value != null) {
  //     if (target.value.toString().match(/^[+-]?([0-9]*[.]?)?([0-9]*)?$/)) {
  //       // console.log(target.value);
  //       const user_input =target.value != null ? target.value : 0;
  //       const user_value = target.value != null ? +target.value / currentUnit!.multiplier : 0;
  //       // console.log("Output: " + output_value);
  //       props.onIonInput(props.controlId, user_input.toString(), user_value);
  //     } else {
  //       props.onIonInput(props.controlId, displayValue.toString(), displayValue);
  //     }
  //   }

  //   // }
  //   // const output_value = 0 ; //target.value ? +target.value : 0;
  //   // console.log(output_value);
  //   // props.onIonInput(props.controlId, output_value);
  // };

  const inputChangeHandler = (event: IonInputCustomEvent<InputEvent>) => {
    const target = event.target;
    // const regEx = /^-?[0-9]*[.][0-9]+$/;
    // console.log(regEx.test(target.value!.toString()));
    // if (!regEx.test(target.value!.toString())) {
    //   props.onIonInput(props.controlId, props.value);
    //   return;
    // } else {

    if (target.value != null) {
      if (target.value.toString().match(/^[+-]?([0-9]*[.]?)?([0-9]*)?$/)) {
        // console.log(target.value);
        const user_input =target.value != null ? target.value : 0;
        const user_value = target.value != null ? +target.value / currentUnit!.multiplier : 0;
        // console.log("Output: " + output_value);
        props.onIonInput(props.controlId, user_input.toString(), user_value);
      } else {
        props.onIonInput(props.controlId, displayValue.toString(), displayValue);
      }
    }

    // }
    // const output_value = 0 ; //target.value ? +target.value : 0;
    // console.log(output_value);
    // props.onIonInput(props.controlId, output_value);
  };

  // const inputKeyDownHandler = (
  //   evt: React.KeyboardEvent<HTMLIonInputElement>
  // ) => {
  //   var charCode = evt.which ? evt.which : evt.keyCode;
  //   // console.log(charCode);
  //   if (
  //     charCode !== 46 &&
  //     charCode !== 190 &&
  //     charCode > 31 &&
  //     (charCode < 37 || charCode > 40) &&
  //     (charCode < 48 || charCode > 57)
  //   ) {
  //     evt.preventDefault();
  //     return false;
  //   }
  //   return true;
  // };

  const unitChangeHandler = (
    event: IonSelectCustomEvent<SelectChangeEventDetail<any>>
  ) => {
    setCurrentUnit(event.detail.value);
    const newValue = myUtil.pfn(+props.value * event.detail.value.multiplier);
    props.onIonInput(props.controlId, newValue.toString(), props.value);
  };

  const inputFocusHandler = (
    event: React.FocusEvent<HTMLIonInputElement, Element>
  ) => {
    event.target.value = displayInput;
  };

  const inputBlurHandler = (
    event: React.FocusEvent<HTMLIonInputElement, Element>
  ) => {
    event.target.value = displayValue;
    if (event.target.value.toString() === "")
      props.onIonInput(props.controlId, "", 0);
    // console.log(props.value);
  };

  return (
    <div className={classes.root}>
      <IonItem lines="none">
        <div slot="start" className={classes["input-start"]}>
          <div
            className={`${classes.short} ${
              classes["l" + currentUnit?.short.length]
            }`}
          >
            {currentUnit?.short}
          </div>
          <div className={classes.second}>
            <div className={classes["cha-label"]}>{props.label}</div>
            <IonSelect
              placeholder={props.subLabel}
              className={`${classes["cha-select"]} ion-no-padding`}
              title={props.label}
              interface="popover"
              compareWith={compareWith}
              value={currentUnit}
              // onIonChange={(ev) => setCurrentUnit(ev.detail.value)}
              onIonChange={unitChangeHandler}
            >
              {props.items.map((item) => (
                <IonSelectOption key={item.id} value={item}>
                  {item.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
        </div>
        <IonInput
          {...props}
          className={`${inputClasses}`}
          type="number"
          value={displayInput}
          maxlength={12}
          min={0}
          onIonInput={inputChangeHandler}
          // onKeyDown={inputKeyDownHandler}
          onBlur={inputBlurHandler}
          onFocus={inputFocusHandler}

          // pattern="\/d+"
          // props.onIonInput &&
          // props.onIonInput(
          //   e.target as HTMLIonInputElement,
          //   props.controlId,
          //   currentUnit!.multiplier
          // )
          // }
          // onIonBlur={(e) => (e.target.value = displayValue)}
          // onIonFocus={(e) => (e.target.value = displayValue)}
        ></IonInput>
      </IonItem>
    </div>
  );
};
export default SelectTextBox;
