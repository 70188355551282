import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { addCircleSharp } from "ionicons/icons";
import { useState } from "react";
import Header from "../components/Header";
import Settings from "../components/Settings";
import Sidebar from "../components/Sidebar";
import myUtil, { IS_EMBED } from "../helpers/cha-utils";
// import ChaBasicListType from "../types/BasicListType";
import SelectTextBox from "../components/SelectTextBox";
import ChaUnit from "../interfaces/Unit";
import ResultSection from "../components/ResultSection";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import classes from "./index.module.css";

// const getSavedData = () => {
//   const json_data = localStorage.getItem(myUtil.prefix + "saved-items");
//   if (json_data) return JSON.parse(json_data);
//   else return [];
// };

const ohmUnit: ChaUnit[] = [
  { id: 1, short: "MΩ", name: "mega-ohms", multiplier: 0.000001 },
  { id: 2, short: "kΩ", name: "kilo-ohms", multiplier: 0.001 },
  { id: 3, short: "Ω", name: "ohms", multiplier: 1 },
  { id: 4, short: "mΩ", name: "milli-ohms", multiplier: 1000 },
];

const Index: React.FC = () => {
  const [controlItems, setControlItems] = useState<
    { id: number; input: string; value: number }[]
  >([
    { id: 1, input: "", value: 0 },
    { id: 2, input: "", value: 0 },
  ]);

  // console.log(controlItems);

  // // const mulitplier = 0.00003527396195;
  const [decimalValue, setDecimalValue] = useState<number>(9);

  let result = 0;
  controlItems.forEach((item) => (result += 1 / item.value));
  // console.log(result);

  // const [formData, setFormData] = useState<{ [key: string]: number }>({
  //   mg: 1,
  //   oz: mulitplier,
  // });

  // let displayData: { [key: string]: number } = {};
  // Object.entries(formData).forEach(
  //   ([key, value]) =>
  //     (displayData[key] = myUtil.prepareFloatingNumber(value, decimalValue))
  // );

  // const [items, setItems] = useState<ChaBasicListType[]>(getSavedData());

  // useEffect(() => {
  //   localStorage.setItem(myUtil.prefix + "saved-items", JSON.stringify(items));
  // }, [items]);

  const inputChangeHandler = (
    controlId: number,
    user_input: string,
    user_value: number
  ) => {
    // const currentControl = controlItems.find((item) => item.id === controlId);
    // console.log("multiplier: " + mulitplier);
    // if (![null, "", false].includes(input_value.toString())) {
    // console.log(target.value);
    // if (!target.value) return;
    // if (!currentControl) return;
    setControlItems((oldItems) => {
      return oldItems.map((item) => {
        item.input = item.id === controlId ? user_input : item.input;
        item.value = item.id === controlId ? +user_value : item.value;
        return item;
      });
    });
  };

  // if (!target.value) return;
  // const input = target.name;
  // const input_value = +target.value;
  // let output = "";
  // let output_value = 0;

  // switch (input) {
  //   case "mg":
  //     output = "oz";
  //     output_value = input_value * mulitplier;
  //     break;
  //   case "oz":
  //     output = "mg";
  //     output_value = input_value / mulitplier;
  //     break;
  // }

  // setFormData((oldData) => {
  //   return {
  //     ...oldData,
  //     [input]: input_value,
  //     [output]: output_value,
  //   };
  // });
  // };

  const addButtonHandler = () => {
    setControlItems((oldItems) => [
      ...oldItems,
      { id: oldItems.length + 1, input: "", value: 0 },
    ]);
  };

  const controlItemsJSX = controlItems.map((item) => (
    <IonRow key={item.id}>
      <IonCol>
        <SelectTextBox
          controlId={item.id}
          label={`Resistor ${item.id}`}
          subLabel="Select unit"
          textSize={3}
          input={item.input}
          value={item.value}
          items={ohmUnit}
          onIonInput={inputChangeHandler}
        />
      </IonCol>
    </IonRow>
  ));

  const calculate = () => {
    let result = 0;
    controlItems.forEach((item) => {
      result += item.value !== 0 ? 1 / item.value : 0;
    });
    result = result !== 0 ? 1 / result : 0;
    return result;
  };

  result = calculate();

  const resultJSX =
    result !== 0
      ? myUtil.formatNumber(result, decimalValue) + " Ω"
      : "Please enter at least one resistor value";

  //step 1
  let step0: string[] = [];
  let step0Id = 1;
  controlItems.forEach((item) => {
    if (item.value > 0)
      step0.push(
        "\\frac{1}{R_{" + step0Id + "}}"
      );
      step0Id++;
  });
  const step0_text = step0.length > 0 ? "\\frac{1}{R}=" + step0.join("+") : "";

  //step 1
  let step1: string[] = [];
  controlItems.forEach((item) => {
    if (item.value > 0)
      step1.push(
        "\\frac{1}{" +
          myUtil.formatNumber(item.value, decimalValue) +
          "\\space Ω}"
      );
  });
  const step1_text = step1.length > 0 ? "\\frac{1}{R}=" + step1.join("+") : "";

  //step 2
  let step2: string[] = [];
  controlItems.forEach((item) => {
    if (item.value > 0)
      step2.push(
        "\\frac{" + myUtil.formatNumber(1 / item.value, decimalValue) + "}{Ω}"
      );
  });
  const step2_text = step2.length > 0 ? "\\frac{1}{R}=" + step2.join("+") : "";

  //step 3
  let step3 = 0;
  controlItems.forEach((item) => {
    if (item.value > 0) step3 += 1 / item.value;
  });
  const step3_text =
    step3 > 0
      ? "\\frac{1}{R}=\\frac{" +
        myUtil.formatNumber(step3, decimalValue) +
        "}{Ω}"
      : "";

  const step4_text =
    step3 > 0
      ? "{R}=\\frac{1}{" +
        myUtil.formatNumber(step3, decimalValue) +
        "}\\ {Ω}"
      : "";

  const formulaJSX = (
    <div style={{textAlign:"center", margin: "15px 0 0 0"}}>
    <BlockMath math="\frac{1}{R} = \frac{1}{R_1} + \frac{1}{R_2} + ... + \frac{1}{R_n}"></BlockMath>
    <img className={classes['formula-image']} alt="N amount of resistor connected in parallel" src="assets/images/parallel-resistor-image.png" />
    </div>
  );
  let calculationJSX = (
    <IonList lines="full" className="ion-no-padding">
      {step0_text !== "" && (
      <p className="ion-padding-top">Here are the steps to calculate the equivalent of all resistors connected in parallel.</p>
      )}
      {step0_text !== "" && (
      <IonItem className="ion-no-padding">
        <InlineMath math={step0_text} />
      </IonItem>
      )}
      {step1_text !== "" && (
        <IonItem className="ion-no-padding">
          <InlineMath math={step1_text} />
        </IonItem>
      )}
      {step2_text !== "" && (
        <IonItem className="ion-no-padding">
          <InlineMath math={step2_text} />
        </IonItem>
      )}
      {step3_text !== "" && (
        <IonItem className="ion-no-padding">
          <InlineMath math={step3_text} />
        </IonItem>
      )}
      {step4_text !== "" && (
        <IonItem className="ion-no-padding">
          <InlineMath math={step4_text} />
        </IonItem>
      )}
      {result > 0 && (
        <IonItem className="ion-no-padding">
          <InlineMath
            math={
              `{R}=` + myUtil.formatNumber(result, decimalValue) + `\\ Ω`
            }
          />
        </IonItem>
      )}
    </IonList>
  );

  return (
    <>
      {!IS_EMBED && <Sidebar />}

      <IonPage id="main-content">
        <Header title="Parallel Resistor Calculator" />
        <IonContent className="ion-padding-vertical">
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-align-center">
              <IonCol size="12" sizeMd="6">
                <ResultSection
                  color="primary"
                  title={resultJSX}
                  subtitleTop="Result"
                />
                <IonGrid>
                  {controlItemsJSX}
                  <IonRow>
                    <IonCol className="ion-text-right">
                      <IonButton color="primary" onClick={addButtonHandler}>
                        <IonIcon icon={addCircleSharp} slot="start"></IonIcon>
                        <IonLabel>Add Resistor</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                {step0_text !== "" && (
                <ResultSection
                color="primary"
                  subtitleTop="Calculation"
                  content={calculationJSX}
                />
                )}
                <ResultSection subtitleTop="Formula" color="primary" content={formulaJSX} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonGrid fixed={true}>
              <IonRow>
                <IonCol>
                  <Settings
                    decimalValue={decimalValue}
                    onRangeUpdated={(v) => setDecimalValue(+v)}
                  ></Settings>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default Index;
